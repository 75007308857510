.calendar-container {
    width: 100vw; /* 100% of the viewport width */
    height: 100vw; /* 100% of the viewport height */
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
    overflow: hidden; /* Ensure no scrollbars */
    margin-top:150px;
    margin-bottom:40px;
    max-height:900px;
  }
  
  .calendar-container .carousel {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .calendar-container .carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    margin-left:30px;
    margin-right:30px;
    cursor: pointer;
    z-index: 1;
  }
  
  .calendar-container .carousel button:first-child {
    left: 0;
  }
  
  .calendar-container .carousel button:last-child {
    right: 0;
  }
  
  .calendar-container iframe {
    width: calc(100vw - 100px); /* 100% of the container's width */
    height: calc(100vw - 100px);  /* 100% of the container's height */
    max-height:800px;
    border: none; /* Remove border */
    padding:40px;
  }
  