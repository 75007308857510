/* locations */
.locations-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .locations-container p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
  }