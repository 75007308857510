.delete-products-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .delete-products-header {
    color: #333;
    text-align: center;
    font-size: xx-large;
  }
  
  .products-list {
    list-style-type: none;
    padding: 0;
  }
  
  .product-item {
    margin: 10px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 4px;
  }
  
  .delete-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #ff7875;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: fit-content;
    max-width: 90%; 
  }
  
  .confirm-button, .cancel-button {
    margin: 10px;
    cursor: pointer;
  }
  
 
  .confirm-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .cancel-button {
    background-color: gray;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
  }