.home {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: 10%;
  

}
.headerContainer {
  width: 100%;
  height: 40vh;
  padding-bottom: 3%;
  align-items: flex-start;
}

.middleContainer {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 3%;
}

.bottomContainer {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 3%;
  width: 70%;
}


@media only screen and (max-width: 1000px) {
  .home {
    justify-content: center;
    align-items: center;
  }




  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #070707;
    color: white;

  }

  .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: rgb(255, 255, 255);
  }

  .headerContainer button {
    background-color: rgb(129, 129, 129);
    color: #fdfdfd;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: #66B2B2;

    color: #ffffff;
  }

  .bodyContainer {
    align-items: center;
    font-size: medium;
    align-items: flex-start;
    flex-direction: column;
    background-position: center;
  }

  .bodyContainer .h2 {
    align-items: center;
    font-size: medium;
    align-items: flex-start;
    flex-direction: column;
    background-position: center;
  }
}