.contact {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
}

.contact .leftSide {
  height: 100vh;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.contact .content-wrapper {
  padding-top: 120px; /* Adjust this value based on navbar's height */
}

.contact .rightSide {
  height: 100%;
  margin-top: 100px;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

.contact form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  font-size: large;
}

.contact form input {
  height: 40px;
  width: 80%;
  font-size: xx-large;
  border: none;
  border-bottom: 1px solid #121619;
  color: rgb(0, 0, 0);
}

.contact form textarea {
  margin-top: 15px;
  height:auto;
  width: 80%;
  font-size: xx-large;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: rgb(95, 95, 95);
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

form button {
  margin-top: 40px;
  width: 180px;
  height: 80px;
  border: none;
  background-color: #9b9b9b;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;

  /* Center text vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Add a smooth transition effect */
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color:  rgb(35, 52, 78); /* Change color on hover */
}

/* after form is created css to split location in half */

.locationInfo {
display: flex;
justify-content: center; 
align-items: center; 
width: 100%;
height: 100%; 
}

@media  screen and (max-width: 500px) {
/* Stack items vertically on small screens */
.locationInfo {
  flex-direction: column; 
}
/* Hide the left image for small screens*/
.contact .leftSide {
  display: none; 
}
 /* Expand the right side */
.contact .rightSide {
  flex: 100%;
}
}

.leftLocation,
.rightLocation {
width: 400px; /* Set a fixed width for the location boxes */
margin: 0 5px; /* Adjust margin for a smaller gap */
height: 400px; /* Set a fixed height for the location boxes */
overflow: auto; /* Add overflow property if content exceeds the height */
}

.leftLocation {
background-color: #f0f0f0; 
}

.rightLocation {
background-color: #f0f0f0; 
}

.leftLocation ul,
.rightLocation ul {
list-style: none;
padding: 0;
}