  /* Admin page layout */
  .admin {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Sidebar styles */
  .adminSide .SidebarLeft {
    padding: 1.3%;
    width: 200px;
    background-color: #bdbdbd;
  }
  
  /* Styles for the list items */
  .adminSide .SidebarLeft .row {
    height: 70px;
    list-style: none;
    color: #000;
    justify-content: center;
    align-items: center;
  }
  
  /* Interactivity */
  .adminSide .SidebarLeft .row:hover,
  .SidebarLeft .dropdown-item:hover {
    cursor: pointer;
    background-color: rgb(131, 202, 210);
  }
  
  /* Active link styling */
  .adminSide .SidebarLeft #active {
    background-color: rgb(131, 202, 210);
  }
  
  /* Logout specific styles */
  .SidebarLeft .row.logout:hover {
    background-color: red;
    color: #ffffff;
  }
  
  /* Right side where the component will be displayed */
  .adminRightSide {
    flex: 1;
    padding: 20px;
    height: auto;
    width: 100%;
  }
  