/* Profile.css */


.profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px; 
  margin-top: 10%;
  margin-bottom: 5%;
}


.profile-info {
  text-align: center;
  margin-bottom: 20px;
}

.profile-info img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info h2 {
  margin: 10px 0;
}


input[type="text"],
textarea,
input[type="number"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}


button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}


.tournaments-table {
  margin-top: 20px;
}

.tournaments-table table {
  width: 100%;
  border-collapse: collapse;
}

.tournaments-table th,
.tournaments-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.tournaments-table th {
  background-color: #f2f2f2;
}


.footer {
  margin-top: auto;
}
