
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(35, 52, 78); /* Blueish color with transparency */
  backdrop-filter: blur(8px); /* Adds blur to the background */
  color: white;
  padding: 4px 20px; /* Slightly reduced vertical padding */
  margin-top: 20px;
  position: fixed;
  top: 0;
  left: 50%; /* Centering the navbar horizontally */
  transform: translateX(-50%); /* Align center properly */
  width: 90%;
  z-index: 1000;
  border-radius: 10px; /* Adds rounded corners to the navbar */
}

.navbar img {
  max-height: 80px; /* Adjust based on logo's aspect ratio to reduce navbar height */
  width: auto; /* Adjust width automatically to keep the logo's aspect ratio */
}

.user-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.user-dropdown:hover .dropdown-content {
  display: block;
}

.link-style {
  background-color: transparent;
  border: none;
  font-size: 21px;
  color: black;
  box-shadow: none;
  margin-top: 0px;
  border-radius: 0px;
}

.links a, .links form {
  margin: 8px; /* Slightly reduced margin */
  color: white;
  text-decoration: none;
  font-size: 21px; /* Adjusted for balance */
  padding: 8px 15px; /* Reduced padding for less vertical space */
  display: inline-block; /* Ensures padding is applied correctly */
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-results-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  border: 5px solid #fff;

  z-index: 1000;
  width: calc(100% - 20px); /* Adjust width to account for padding */
  max-height: 300px;
  overflow-y: auto;
  top: 100%; /* Ensure dropdown starts right below the input field */
  left: 0;
  margin: 0 10px; /* Add some margin if needed */
}

.search-result-item {
  background-color: rgb(35, 52, 78);
  display: flex;
  align-items: stretch; /* Align items to stretch to fill the container */
  padding: 10px;
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #ddd;
}

.search-result-item:hover {
  background-color: #f7f7f7;
  color: #333;
}

.search-result-image {
  flex: 0 0 50px; /* Fixed width, don't grow or shrink */
  height: auto; /* Adjust height automatically */
  object-fit: cover; /* Adjust the display of the image */
  margin-right: 10px;
}

.search-result-text {
  flex-grow: 1; /* Allow the text container to take up remaining space */
  display: flex;
  flex-direction: column; /* Stack name and description vertically */
}

.search-result-name {
  font-weight: bold; /* Make the name stand out */
  margin-bottom: 5px; /* Space between name and description */
}

.search-result-description {
  font-size: 0.8rem; /* Smaller font size for descriptions */
  color: #fff; /* Set color to white */
}
.search-result-item:hover {
  background-color: #f7f7f7; /* Adjust if you want a different background on hover */
  color: #000; /* Black text color on hover for visibility */
}

/* If you have specific hover color requirements for name or description */
.search-result-item:hover .search-result-name,
.search-result-item:hover .search-result-description {
  color: #000; /* Ensuring visibility against a lighter background */
}

.links.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  background-color: rgba(0, 123, 255, 0.8); /* Apply transparency to dropdown */
  backdrop-filter: blur(8px); /* Adds blur to the dropdown background */
  width: 100%;
  text-align: center;
}

input[type="search"], button {
  padding: 10px 18px; /* Slightly reduced padding */
  margin-right: 10px; /* Maintains spacing */
  border: none;
  border-radius: 15px;
  font-size: 16px; /* Keeps text inside input and button comfortably large */
  background-color: rgba(0, 0, 0, 0.2); /* Darker transparent background */
  color: white; /* White text */
}

button {
  cursor: pointer;
}


.dropdown-content div, .dropdown-content a, .dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover, .dropdown-content a:hover, .dropdown-content {
  background-color: #f1f1f1;
}


@media screen and (max-width: 768px) {
  .links {
    display: none;
  }
  .links.show {
    display: block;
  }
}

