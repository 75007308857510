/* Dropdown styles */
.dropdownlocations {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-trigger {
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #aaaaaa;
  }
  
/* Show the dropdown content on hover */
.dropdownlocations:hover .dropdown-content {
  display: block;
}

/*Admin Dropdown*/
.product-title {
  color: red; 
}

.dropdown-trigger:hover .product-title {
  color: white; 
  
}