/* About.css */
.about{
  margin-top: 10%;
}
/* Container styles */
.about .title {
    text-align: center;
    margin-top: 20px;
  }
  .about .icon {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .about .icon img {
    max-width: 500px; /* Set the maximum width of the image */
    width: auto; 
    height: auto; 
    display: flex;
    margin: 0 auto;
    padding: 0px; /* Smaller padding */
  }
  
  
  /* Flex container for map sections */
  .about .maps-container {
    display: flex;
    justify-content: space-around; /* Adjust as needed */
    margin-top: 20px;
  }
  
  /* Map container styles */
 
  .about .map1{
    text-align: center;
   
  }

  .about .map2 {
    text-align: center;
    margin-left: 25px; /* Add margin between maps */
  }
  
  /* Location icon styles */
  .about .location img {
    width: 20px; 
    height: auto; /* Maintain aspect ratio */
  }
  
  .about .location {
    margin-top: 10px;
  }
  
  /* Ensure the Google Maps iframe is responsive */
  iframe {
    width: 400px;
    height: 200px;
    border: 0;
  }
  .about .map1 .hours dd, 
  .about .map2 .hours dd{
    font-size: 30px ;
    margin: 5px 0; /* Adjust the top and bottom margin to control the spacing */
  }
  
  .about .map1 .phone, 
  .about .map2 .phone{
    font-size: 25px;
    padding: 5px;
  }
  .about .map1 .phone img,
  .about .map2 .phone img{
    width: 15px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Adjustable values */
  .about :root {
    --margin-top: 20px;
    --icon-width: 20px;
  }
  .about .apps {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .about .apps img {
    width: 20px; /* Adjust the width to make the icons smaller */
    height: 20px; /* Adjust the height proportionally */
    margin: 0 5px;
  }

  /* text about stles */
  .aboutBottom {
    display: flex;
    flex-direction: column;
    height: 55%;
    align-items: center;
    margin-top: 50px;
  }
  
  .aboutBottom p {
    font-size: xx-large;
    width: 1200px;
  }
  
  .about .aboutBottom h1 {
    font-weight: 400;
    font-size: 70px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
  }

  .about .aboutBottom h2 {
    font-weight: 400;
    font-size: 40px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
 
  }

  .about .aboutBottom h3 {
    font-weight: 500;
    margin-left: center;
    font-size: 30px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;

  }