/* ProductDetail.css */
.product-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #ffffff;
    margin: 20px auto;
    max-width: 1000px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    margin-top: 10%;
  }
  
  .back-to-products {
    margin-bottom: 30px;
    font-size: 16px;
    color: #333;
    background-color: transparent;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #66B2B2;
  }
  
  .back-to-products:hover {
    background-color: #66B2B2;
    color: white;
  }
  .star {
    color: goldenrod;
    font-size: 25px;
  }
  .star-rating-container {
    display: flex;
    flex-direction: row; /* This ensures horizontal layout */
    justify-content: center; /* Centers the stars within the container if you want */
    gap: 5px; /* Optional: adds space between stars */
  }
  
  .review-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
  }
  
  .rating-container,
  .review-text-container {
    margin-bottom: 15px;
  }
  
  .review-text-container textarea {
    width: 100%; /* Adjust based on your layout */
    height: 100px;
    margin-top: 5px;
  }
  
  .submit-review-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .submit-review-btn:hover {
    background-color: #0056b3;
  }
  button.on .star {
    color: goldenrod;
  }
  
  button.off .star {
    color: #ccc;
  }

  .product-detail-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 5px;
  }
  
  .product-detail-name {
    margin: 20px 0 10px;
    color: #222;
    font-size: 24px;
  }
  
  .product-detail-description {
    text-align: justify;
    margin: 0 auto 20px;
    color: #555;
    font-size: 16px;
    max-width: 500px;

  }
  
  .product-detail-price {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    padding: 10px;
    border-top: 1px solid #eee;
    width: 100%;
    text-align: center;
  } 

.product-image-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.product-thumbnail {
  width: 50px; /* Adjust size as needed */
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
}

.product-thumbnail:hover,
.product-thumbnail.active {
  border-color: #66B2B2; /* Highlight on hover and when active */
}