.booking-form {
  margin-bottom: 20px;
  padding: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10%;
}

.booking-form h2 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}

.booking-form p {
  text-align: center;
  margin-top: 10px;
  color: #666666;
}

.pricing-information h2,
.payment-section h2 {
  text-align: left;
}

.availability-display, .pricing-information, .payment-section, .confirmation-page {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  padding: 12px 24px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #45a049;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333333;
}

input[type="date"],
select,
input[type="number"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-sizing: border-box;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23000' d='M7.1 9.9l4.5 4.5 4.5-4.5c.6-.6.6-1.6 0-2.2-.6-.6-1.6-.6-2.2 0L10 11.7 7.2 8.9c-.6-.6-1.6-.6-2.2 0-.6.6-.6 1.6 0 2.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 20px auto;
}

@media screen and (max-width: 600px) {
  .booking-form, .availability-display, .pricing-information, .payment-section, .confirmation-page {
    padding: 15px;
  }
}
