.adminnavbar {
  width: 100%;
  height: 120px;
  background-color: #bdbdbd;
  display: flex;
  flex-direction: row;
}

.adminnavbar .leftSide, .adminnavbar .rightSide {
  flex: 50%;
  display: flex;
  align-items: center;
}

.adminnavbar .leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 150px;
}

.adminnavbar .leftSide img {
  width: 130px;
}
.adminnavbar .leftSide .admin-link{
  font-size: 60px;
}
.adminnavbar .rightSide {
  flex: 50%;
  font-size: x-large;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminnavbar p{
  font-size: 40px;
  color: red;
  margin-left: 10px;
}

.admin-link {
  text-decoration: none; 
  color: red; 
  transition: color 0.3s; 
  padding: 10px 15px; 
  border-radius: 5px;
  background-color: transparent; 
}
.admin-link:hover {
  color: #ffffff; 
}