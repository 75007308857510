.slideshow-wrapper {
  display: flex;
  justify-content: center;
  /* Center the slideshow container */
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;


}
.slideshow-container p,
.slideshow-container h1,
.slideshow-container h2 {
  color: black;

}
.slideshow-container {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease;
  width: 100%;
  /* Ensure container takes full width */
}

.slide {
  width: 100%;
  /* Each slide takes full width of the container */
  flex-shrink: 0;
  /* Prevents the slide from shrinking */
  display: none;
  /* Hide slides by default */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
}

.slide.active,
.slide.prev,
.slide.next {
  display: block;
  /* Only show active, prev, and next slides */
}

.slide.active {
  z-index: 2;
  opacity: 1;
  filter: none;
  position: relative;
  /* Ensure active slide is positioned relatively for content positioning */
}

.slide-content {
  position: absolute;
  left: 25%;
  bottom: 20px;
  transform: translateX(-50%);
  color: white;
  text-align: left;
  /* Align text to the left */
}

/* Additional CSS for prev and next slides */
.slide.prev,
.slide.next {
  position: absolute;
  /* Absolute positioning to overlay on sides */
  width: 15%;
  /* Smaller width for prev/next slides */
  height: 80%;
  z-index: 1;
  opacity: 0.5;
  /* Less opacity for non-active slides */
  
}

.slide.prev {
  left: 0;
  filter: blur(2px);
}

.slide.next {
  right: 0;
  filter: blur(2px);
}

.slide-controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 3;
  /* Ensure controls are above all slides */
}


.dot {
  height: 10px;
  width: 30px;
  margin: 0 5px;
  background-color: grey;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: rgb(35, 52, 78);
}