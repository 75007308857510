.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    width: 500px;
    background:white;
    padding-bottom: 30px;
    min-height: 878px;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
}
.text {
    color: gray;
    font-size: 79px;
    height: 120px;
    background: white ;
    border-radius: 9px;
}
.underline{
    width: 500px;
    height: 6px;
    background:#23344E;
    border-radius: 9px;

}

.inputs{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.input {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    margin: auto;
    width: 480px;
    height: 100px;
    color: black;
    border-radius: 6px;
    border: 2px solid #000000; 
    box-sizing: border-box; 
  }

.input img{
    margin: 90px 20px;
    width: 40px;
    height: 40px;
}
.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    font-size: 19px;
}
.forgot-password{
    color: black;
    font-size: 20px;
    margin:auto;
}
.forgot-password span{
    color: rgb(255, 12, 12);
    cursor: pointer;
}
.submit-container{
    display: flex;
    gap: 30px;
    margin: 10px auto;
}
.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: white;
    background-color: #23344E;
    border-radius: 60px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
}
.gray{
    background: white;
    color: gray;
}

.email-input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: 2px solid #000000; /* Added border styling */
    border-radius: 10px; /* Added border radius */
    outline: none;
    color: black;
    font-size: 19px;
}

.reset-password-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: white;
    background-color: #23344E;
    border-radius: 60px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
}

.icon {
    
    height: 100px;
    margin-bottom: 200px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 250px;
    width: 100px;

}

.google-signin {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center items horizontally */
  }
  
  .google-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px; /* Adjust spacing between icon and text */
  }
  
  .google-text {
    font-size: 16px; /* Adjust font size */
    line-height: 24px; /* Align text vertically with icon */
    display: flex;
    align-items: center; /* Center text vertically */
  }
  