/* AddEvents.css */

.adminContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .adminSide {
    width: 20%;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .addEventForm {
    width: 75%;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="date"],
  input[type="url"],
  textarea,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .eventList {
    margin-top: 30px;
  }
  
  .eventList ul {
    list-style-type: none;
    padding: 0;
  }
  
  .eventList li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logout {
    color: red;
  }
  
  .logout:hover {
    color: darkred;
  }
  