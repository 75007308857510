.adminContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.adminSide {
  width: 200px;
}

.addProductForm {
  flex-grow: 1;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group, .urlInputGroup {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjusts space between label and input */
}

.form-group label, .featuredProductLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type='text'],
.form-group input[type='number'],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addProductForm input[type='text'],
.addProductForm input[type='number'],
.addProductForm textarea,
.addProductForm button {
  width: 100%; /* Ensures full width within parent container */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Includes padding and border in the element's total width */
}

.removeImageButton {
  background-color: #5e1a1c;
  color: white;
  cursor: pointer;
}

.addImageButton {
  background-color: #203f61;
  color: white;
  cursor: pointer;
}

.addProductForm button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .adminContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .adminSide, .addProductForm {
    width: 100%;
    margin: 10px 0;
  }
}

.addProductForm label {
  color: black; 
}

.addProductForm h2 {
  color: #000000; 
  margin-bottom: 20px; 
  text-align: center; 
  font-size: 50px;
  
}

.form-group.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  gap: 10px;
}


.form-group.checkbox-container label {
  margin-bottom: 0; 
  font-weight: normal; 
  display: inline-block; 
}


.form-group.checkbox-container input[type='checkbox'] {
  width: 50px; 
  height: 50px; 
  vertical-align: middle; 
}

.form-group label, .featuredProductLabel, .slideShowLabel {
  font-size: 25px;
  margin-bottom: 10px;
}

.form-group input[type='text'],
.form-group input[type='number'],
.form-group textarea {
  padding: 15px; 
  font-size: 16px; 
  border: 2px solid #ccc; 
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: fit-content;
  max-width: 90%; /* Prevents the modal from being too wide on small screens */
}

.confirm-button, .cancel-button {
  margin: 10px;
  cursor: pointer;
}

/* Add styles for buttons to improve UI */
.confirm-button {
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.cancel-button {
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}