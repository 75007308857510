/* Scoped to only affect elements within .my-reservations */
.my-reservations {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items horizontally at the center */
  min-height: 100vh;
}

.my-reservations table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 20px; 
  margin-bottom: 30px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 16px;
}

.my-reservations th, .my-reservations td {
  text-align: left;
  padding: 8px;
}

.my-reservations th {
  background-color: #f2f2f2;
}

.my-reservations tr:nth-child(even) {
  background-color: #f9f9f9;
}

.my-reservations td {
  border-bottom: 1px solid #ddd;
}

.my-reservations th {
  color: #333;
  font-weight: bold;
}

.my-reservations div {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.my-reservations h1 {
  color: #333;
  padding-bottom: 20px;
  margin-top: 10%; 
  margin-bottom: 30px; 
  margin-left: 5%;
  margin-right: 5%;
}

/* Footer positioned at the bottom */
.my-reservations .footer {
  margin-top: auto;
}